import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './material.module';

const routes: Routes = [
  { path: '', component:LoginComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true }),MaterialModule],
  exports: [RouterModule],
  declarations: [LoginComponent]
})
export class AppRoutingModule { }
